/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PromotionTagAdditionalInfoResponse = {
  id?: number;
  promotionId?: number;
  discountType?: PromotionTagAdditionalInfoResponse.discountType;
  value?: number;
  typeIncluded?: PromotionTagAdditionalInfoResponse.typeIncluded;
  fieldIDsIncluded?: Array<number>;
  typeExcluded?: PromotionTagAdditionalInfoResponse.typeExcluded;
  fieldIDsExcluded?: Array<number>;
};

export namespace PromotionTagAdditionalInfoResponse {

  export enum discountType {
    AMOUNT = 'AMOUNT',
    PERCENTAGE = 'PERCENTAGE',
    FORCED_PRICE = 'FORCED_PRICE',
  }

  export enum typeIncluded {
    BUSINESS_PARTNER_GROUP = 'BUSINESS_PARTNER_GROUP',
    BUSINESS_PARTNER = 'BUSINESS_PARTNER',
    ITEM_GROUP = 'ITEM_GROUP',
    ITEM = 'ITEM',
    PRICE_LIST = 'PRICE_LIST',
    COUPON = 'COUPON',
    CAMPAIGN = 'CAMPAIGN',
    PRICE = 'PRICE',
    LOYALTY_LEVEL = 'LOYALTY_LEVEL',
  }

  export enum typeExcluded {
    BUSINESS_PARTNER_GROUP = 'BUSINESS_PARTNER_GROUP',
    BUSINESS_PARTNER = 'BUSINESS_PARTNER',
    ITEM_GROUP = 'ITEM_GROUP',
    ITEM = 'ITEM',
    PRICE_LIST = 'PRICE_LIST',
    COUPON = 'COUPON',
    CAMPAIGN = 'CAMPAIGN',
    PRICE = 'PRICE',
    LOYALTY_LEVEL = 'LOYALTY_LEVEL',
  }


}
