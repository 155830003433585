/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DiscountPost } from './DiscountPost';
import type { RulePost } from './RulePost';

export type PromotionTemplatePost = {
  applicationType?: PromotionTemplatePost.applicationType;
  description: string;
  checkLoyaltyBP: boolean;
  proposal?: boolean;
  discounts: Array<DiscountPost>;
  rules: Array<RulePost>;
  ptype: PromotionTemplatePost.ptype;
};

export namespace PromotionTemplatePost {

  export enum applicationType {
    DEFAULT = 'DEFAULT',
    BEST_CHOICE = 'BEST_CHOICE',
    CUMULATIVE = 'CUMULATIVE',
  }

  export enum ptype {
    TOTAL = 'TOTAL',
    BRACKETS = 'BRACKETS',
    CASHBACK = 'CASHBACK',
    PRICE_LIST = 'PRICE_LIST',
    OPERATOR_CHOICE = 'OPERATOR_CHOICE',
    LESS_PRICE = 'LESS_PRICE',
    HIGH_PRICE = 'HIGH_PRICE',
    ITEMS = 'ITEMS',
    POINTS = 'POINTS',
    GIFTS = 'GIFTS',
  }


}
