import React, { useEffect, useState } from "react";
import { BaseTextFieldProps, Button, CircularProgress, Stack, TextField, Typography } from "@mui/material";
import { DatePicker, LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { Controller, useForm } from "react-hook-form";
import { AsyncVoidFunction } from "../../../../../utils/Functions";

import { StyledTableCell, StyledTableRow } from "../../promotions-table/PromotionsTable.styles";
import { PromoPeriodModel } from "../../../../../domain/features/promotions/models/PromoPeriodModel";
import { promoPeriodActions } from "../../../../shared/constants/Actions";
import { useAuth } from "../../../../shared/hooks/authentication/useAuth";
import { AddPeriodForm } from "../create-promo-period/CreatePromoPeriod";
import { useToggler } from "../../../../shared/hooks/useToggler";
import { PromoModel } from "../../../../../domain/features/promotions/models/PromoModel";
import { PointOfSaleModel } from "../../../../../domain/features/organizations/models/PointOfSaleModel";
import { Close, Delete } from "@mui/icons-material";
import { OrganizationButton } from "../components/organization-button/OrganizationButton";

type PromoPeriodEditFormValues = AddPeriodForm;

interface InactivePeriodRowProps {
  period: PromoPeriodModel;
  refetchPeriods: AsyncVoidFunction;
  promotion: undefined | PromoModel;
  pointsOfSale: undefined | PointOfSaleModel;
}

export const InactivePeriodRow: React.FC<InactivePeriodRowProps> = ({
  period,
  refetchPeriods,
  promotion,
  pointsOfSale,
}) => {
  const { t } = useTranslation("promoPeriods");
  const { organization } = useAuth();
  const { isOpen, open, close } = useToggler();
  const [ selectedIds, setSelectedIds ] = useState(period.activeIds);
  const {
    isOpen: isOrgTreeModalOpen,
    open: openOrgTreeModal,
    close: closeOrgTreeModal,
  } = useToggler();

  const { control, getValues } = useForm<PromoPeriodEditFormValues>({
    defaultValues: {
      startDate: period.startDate,
      endDate: period.endDate,
    },
  });

  // api calls
  const {
    mutate: editPeriod,
    isLoading: isSaving,
    isSuccess: isSaved,
  } = useMutation("editPeriod", async (data: PromoPeriodEditFormValues) => {
    await promoPeriodActions.save(
        organization!,
        period.promotionId,
        period.id,
        {
          startDate: data.startDate,
          endDate: data.endDate,
          activeIds: selectedIds,
        }
    );
    await refetchPeriods();
  });

  const {
    isLoading: isDeleting,
    mutate: deletePeriod,
  } = useMutation("deletePromoPeriod", async (periodId: number) => {
    await promoPeriodActions.delete(organization!, periodId);
    await refetchPeriods();
  });

  const {
    isLoading: isActivating,
    mutate: activatePeriod,
  } = useMutation("activatePromoPeriod", async (periodId: number) => {
    await promoPeriodActions.active(organization!, periodId);
    await refetchPeriods();
  });

  // handlers
  const onSave = () => {
    if (isOpen) {
      editPeriod(getValues());
      close();
    } else {
      open();
    }
  };

  useEffect(() => {
    if (isSaved) {
      close();
    }
  }, [ isSaved ]);

  const isLoading = isSaving || isDeleting || isActivating;
  // if start of period is in the past, disable deleting
  const isEditable = isDeleting || period.startDate < new Date();

  return (
    <StyledTableRow>
      <StyledTableCell>
        <Typography>{promotion?.code}</Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Typography noWrap={true}>{promotion?.name}</Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Controller<PromoPeriodEditFormValues, "startDate">
          rules={{ required: true }}
          render={({ field }) => 
            <DatePicker
              disablePast
              InputProps={{ size: "small" }}
              disabled={!isOpen}
              {...field}
              renderInput={(props: BaseTextFieldProps) => 
                <TextField {...props} />
              }
            />
          }
          name="startDate"
          control={control}
        />
      </StyledTableCell>
      <StyledTableCell>
        <Controller<PromoPeriodEditFormValues, "endDate">
          rules={{ required: true }}
          render={({ field }) => 
            <DatePicker
              disablePast
              InputProps={{ size: "small" }}
              disabled={!isOpen}
              {...field}
              renderInput={(props: BaseTextFieldProps) => 
                <TextField {...props} />
              }
            />
          }
          name="endDate"
          control={control}
        />
      </StyledTableCell>
      <StyledTableCell>
        <OrganizationButton
          isEdit={isOpen}
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
          disabled={!isOpen}
          pointsOfSale={pointsOfSale}
          isOpen={isOrgTreeModalOpen}
          onClick={openOrgTreeModal}
          handleClose={closeOrgTreeModal}
          treeExpanded={true}
        />
      </StyledTableCell>
      <StyledTableCell>
        {!isOpen ? 
          <Stack direction="row" spacing={1} justifyContent="center">
            <LoadingButton
              variant="outlined"
              color="success"
              onClick={() => activatePeriod(period.id)}
              disabled={isLoading || isOpen}
              loading={isActivating}
            >
              {t("activate")}
            </LoadingButton>
            <Button variant="outlined" onClick={open} disabled={isEditable}>
              {t("edit")}
            </Button>
            <LoadingButton
              variant="outlined"
              onClick={() => deletePeriod(period.id)}
              disabled={isEditable || isLoading}
              loading={isDeleting}
              color="error"
            >
              <Delete color={isLoading || isOpen ? "disabled" : undefined} />
            </LoadingButton>
          </Stack> 
          : 
          <Stack direction="row" spacing={1} justifyContent="center">
            <LoadingButton
              disableElevation
              variant={isOpen ? "contained" : "outlined"}
              onClick={onSave}
              loading={isSaving}
              disabled={isEditable || isLoading}
            >
              {isSaving ? <CircularProgress size={10} /> : t("save")}
            </LoadingButton>
            <Button variant="outlined" onClick={() => {
              close();
              setSelectedIds(period.activeIds);
            }}>
              <Close />
            </Button>
          </Stack>}
      </StyledTableCell>
    </StyledTableRow>
  );
};
