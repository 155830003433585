import {
  ManagerApiToManagePromotionsService,
  PromotionCollectionResponse, 
  PromotionPost, 
  PromotionResponse, 
  PromotionTagCollectionResponse,
} from "../../../generated/api";

export class PromoRepository {
  constructor(private readonly service: ManagerApiToManagePromotionsService) {}

  read(organizationId: number, promotionId: number): Promise<PromotionResponse> {
    return this.service.get4({ organizationId, promotionId });
  }

  readAll(organizationId: number, { status }: {status?: boolean} = {}): Promise<PromotionCollectionResponse> {
    return this.service.get3({ organizationId, status });
  }

  getTags(organizationId: number): Promise<PromotionTagCollectionResponse> {
    return this.service.getOrganizationTags({ organizationId });
  }

  activate(organizationId: number, promotionId: number): Promise<void> {
    return this.service.activePromotion({
      organizationId,
      promotionId,
    });
  }

  deactivate(organizationId: number, promotionId: number): Promise<void> {
    return this.service.deactivatePromotion({
      organizationId,
      promotionId,
    });
  }

  save(
    organizationId: number,
    promotionId: undefined | number,
    { requestBody }: {requestBody: PromotionPost}
  ): Promise<PromotionResponse> {
    return this.service.post({ organizationId, promotionId, requestBody }); 
  }
}