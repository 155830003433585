import { Box, Card, CardContent, CardHeader, Divider, Grow, Typography } from "@mui/material";
import { useEffect } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { GiftRulesFormMachine } from "../../../../../../domain/features/promotions/state-machines/RulesFormMachine";
import { AddButton } from "../../../../../shared/add-button/AddButton";
import { useMachineStateSelector } from "../../../../../shared/hooks/useMachineStateSelector";
import { GiftsFormItem } from "./GiftsFormItem";

export interface GiftFormProps {
  machine: GiftRulesFormMachine;
}

export const GiftsForm = ({ machine }: GiftFormProps) => {
  const { t } = useTranslation("createPromo");
  const methods = useForm({ defaultValues: { giftsRules: machine.state.rules } });
  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "giftsRules",
  });
  const { isReadOnly, rulesLenght, rules } = useMachineStateSelector(machine.select((state) => {
    return {
      isReadOnly: state.isReadonly,
      rulesLenght: state.rules.length,
      rules: state.rules,
    };
  }));
  const addRule = () => {
    append(GiftRulesFormMachine.getInitialRule());
    machine.addRule();
  };
  useEffect(() => {
    if (!rulesLenght) {
      addRule();
    }
  }, []);
  return <Card sx={{ mb: 4, backgroundColor: !isReadOnly ? "white" : "transparent" }} variant="outlined">
    <FormProvider {...methods}>
      <CardHeader title={<Typography fontWeight="bold">{t("apply_ITEM").toUpperCase()}</Typography>} disableTypography />
      <CardContent>
        {fields.map((rule, index) => 
          <Grow 
            in
            key={rule.id} 
            appear={index > 0 && !isReadOnly} 
            unmountOnExit
          >
            <Box>
              <GiftsFormItem 
                isAlone={!index && rules.length === 1}
                machine={machine}
                index={index}
                remove={remove}
                isReadOnly={isReadOnly}
                initialRules={rules[index]?.conditions}
              />
              <Divider sx={{ 
                mt: index < fields.length - 1 ? 2 : undefined,
                mb: index < fields.length - 1 ? 2 : undefined,
              }}>
                {!isReadOnly && index === fields.length - 1 && 
                <AddButton 
                  onClick={addRule} 
                  disabled={isReadOnly || rules[index].itemID === undefined} 
                />
                }
              </Divider>
            </Box>
          </Grow>
        )}
      </CardContent>
    </FormProvider>
  </Card>;
};