import type { ReactNode } from "react";
import { createContext, useContext } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { PromoModel } from "../../../../../domain/features/promotions/models/PromoModel";
import { PromoSaveModel } from "../../../../../domain/features/promotions/models/PromoSaveModel";
import { PromoFormMachine } from "../../../../../domain/features/promotions/state-machines/PromoFormMachine";
import { PromoFormState } from "../../../../../domain/features/promotions/state-machines/PromoFormState";
import { Failure } from "../../../../../domain/shared/failures/Failure";
import { ErrorListener, SuccessListener } from "../../../../../utils/Types";
import { useAuth } from "../../../../shared/hooks/authentication/useAuth";
import { promoActions } from "../../../../shared/constants/Actions";
import { isEmpty, isNullish } from "../../../../../utils/Functions";
import { useStateMachineCreator } from "../../../../shared/hooks/useStateMachineCreator";
import { Pages } from "../../../../shared/pages";
import { WrappedLoader } from "../../../../shared/wrapped-loader/WrappedLoader";

interface PromoStore {
  /**
   * State machine to control the form
   */
  readonly machine: PromoFormMachine;
  /**
   * Save a promo
   *
   * When called, it sets {@see isLoading} to true and {@see canSave} to false
   * See also {@see canSave}
   */
  readonly savePromo: (state: PromoFormState) => Promise<void>;
  /**
   * Activate a promo
   *
   * When called, it sets {@see isLoading} to true and {@see canActivate} to false
   See also {@see canActivate}
   */
  readonly activatePromo: () => Promise<void>;
  /**
   * Indicates that the store is invalid, wait for it to be cleaned
   */
  readonly isDirty: boolean;
  /**
   * Determine if there is an operation in progress or loading a machine state. Ex. {@see savePromo}, {@see activatePromo}
   */
  readonly isLoading: boolean;
  /**
   * Determines whether the {@see savePromo} method can be called
   */
  readonly canSave: boolean;
  /**
   * Determines whether the {@see canActivate} method can be called
   */
  readonly canActivate: boolean;
  /**
   * Whether we are currently working on a creation or edit
   */
  readonly isEdit: boolean;
  /**
   * When an action or machine state loading fails this field is populated with the error or {@see Failure}
   */
  readonly errorOrFailure: undefined | unknown | Failure;

  readonly promo: undefined | PromoModel;
  readonly tags: undefined | {
    tag: string;
    description: string;
  }[];
  readonly isSuccess: boolean;
}

interface PromoStoreArgs {
  onSaveFailed?: ErrorListener;
  onSaved?: SuccessListener<void>;
  onActiveFailed?: ErrorListener;
  onActivated?: SuccessListener<void>;
}

/**
 * Allows you to provide the promo store to create/modify/activate it
 * You can access the store with {@see usePromoStore}
 * For more info {@see PromoStore}
 * @param children
 * @constructor
 */
export const useCreatePromoStore = ({ onSaveFailed, onSaved, onActiveFailed, onActivated }: PromoStoreArgs): PromoStore => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();

  const organizationId = user?.organizationId;
  const promoId = isNullish(id) ? undefined : parseInt(id);

  const machine = useStateMachineCreator(() => new PromoFormMachine(), [ promoId ]);

  const {
    data: promo,
    error: readError,
    isLoading: isReading,
    refetch: refetchPromo,
    isSuccess,
  } = useQuery([ "readPromo", organizationId, promoId ], async () => {
    return await promoActions.read(organizationId!, promoId!);
  }, {
    enabled: !isNullish(organizationId) && !isNullish(promoId),
    onSuccess: (promo) => {
      machine.applyPromo(promo);
    },
    cacheTime: 0, 
  });

  const {
    mutateAsync: savePromo,
    error: saveError,
    isLoading: isSavingPromo,
  } = useMutation("savePromotion", async (state: PromoFormState) => {
    const saveModel = machineStateToSaveModel(state);
    const promo = await promoActions.save(organizationId!, promoId, saveModel);
    if (isNullish(promoId)) {
      navigate(Pages.promoEdit(promo.id));
    } else {
      await refetchPromo();
    }
  }, { onError: onSaveFailed, onSuccess: onSaved });

  const {
    mutateAsync: activatePromo,
    error: activeError,
    isLoading: isActivatingPromo,
  } = useMutation("activePromo",async () => {
    await promoActions.activate(organizationId!, promoId!);
    await refetchPromo();
  }, { onError: onActiveFailed, onSuccess: onActivated });

  const { data: tags } = useQuery([ "getTags", organizationId ], async () => {
    return await promoActions.getTags(organizationId!);
  }, { enabled: !isNullish(organizationId) });

  return {
    machine,
    errorOrFailure: Failure.or(readError ?? saveError ?? activeError),
    savePromo,
    activatePromo,
    isDirty: !isNullish(promoId) && isNullish(promo),
    isLoading: isReading || isSavingPromo || isActivatingPromo,
    canSave: isNullish(promo) ? true : !promo.active && isEmpty(promo.code ?? ""),
    canActivate: isNullish(promo) ? false : !promo.active,
    isEdit: !isNullish(promoId),
    promo,
    isSuccess,
    tags,
  };
};

interface PromoStoreProviderProps {
  store: PromoStore;
  children: ReactNode;
}

export const PromoStoreProvider = ({ store, children }: PromoStoreProviderProps) => {
  if (store.isSuccess || !store.isEdit) {
    return <promoStore.Provider value={store}>
      {children}
    </promoStore.Provider>;
  }
  if (store.isLoading) {
    return <WrappedLoader />;
  }
  return <>{JSON.stringify(store.errorOrFailure)}</>;
};

/**
 * Allows you to access the promo store to create/modify/activate it
 * You can provide the store with {@see usePromoStore}
 * For more info {@see PromoStore}
 */
export const usePromoStore = () => useContext(promoStore);

// ============================================================
// INTERNAL CLASS/METHODS
// ============================================================

const promoStore = createContext({} as PromoStore);

const machineStateToSaveModel = ({
  name,
  type,
  amount,
  applyTo,
  repeatArticles,
  repeatAmounts,
  minTotalExpense,
  minTotalArticles,
  loyaltyPartner,
  proposed,
  rules,
  giftsRules,
  useTags,
  tags,
  shortDescription,
  tagsAdditionalInfo,
}: PromoFormState): PromoSaveModel => {
  // Todo should we rename the fields in state to match saveModel?
  // TODO: Test it
  return {
    description: name.value,
    type: type.value,
    amount: {
      type: amount.type,
      value: amount.value,
    },
    repeatArticles: repeatArticles.isChecked ? {
      value: repeatArticles.data.value,
      rules: repeatArticles.data.rules,
    } : undefined,
    repeatAmounts: repeatAmounts.isChecked ? {
      value: repeatAmounts.data.value,
      rules: repeatAmounts.data.rules,
    } : undefined,
    minAmount: minTotalExpense.isChecked ? {
      value: minTotalExpense.data.value,
      rules: minTotalExpense.data.rules,
    } : undefined,
    minQuantity: minTotalArticles.isChecked ? {
      value: minTotalArticles.data.value,
      rules: minTotalArticles.data.rules,
    } : undefined,
    applyTo: {
      type: applyTo.type,
      rules: applyTo.rules,
    },
    isLoyaltyPartner: loyaltyPartner.value,
    proposed: proposed.value,
    rules: rules.value,
    giftsRules: giftsRules.value,
    //eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    tags: tags.value,
    shortDescription: shortDescription.value,
    useTags: useTags.value,
    tagsAdditionalInfo: tagsAdditionalInfo.value,
  };
};
