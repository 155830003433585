/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ConditionResponse } from './ConditionResponse';
import type { DiscountResponse } from './DiscountResponse';
import type { PromotionTagAdditionalInfoResponse } from './PromotionTagAdditionalInfoResponse';
import type { PromotionTagValueResponse } from './PromotionTagValueResponse';
import type { RuleResponse } from './RuleResponse';

export type PromotionResponse = {
  id: number;
  code: string;
  active: boolean;
  applicationType: PromotionResponse.applicationType;
  description: string;
  checkLoyaltyBP: boolean;
  proposal: boolean;
  shortDescription?: string;
  showData: boolean;
  discounts: Array<DiscountResponse>;
  rules: Array<RuleResponse>;
  conditions: Array<ConditionResponse>;
  promotionTagValues?: Array<PromotionTagValueResponse>;
  promotionTagAdditionalInfos?: Array<PromotionTagAdditionalInfoResponse>;
  ptype?: PromotionResponse.ptype;
};

export namespace PromotionResponse {

  export enum applicationType {
    DEFAULT = 'DEFAULT',
    BEST_CHOICE = 'BEST_CHOICE',
    CUMULATIVE = 'CUMULATIVE',
  }

  export enum ptype {
    TOTAL = 'TOTAL',
    BRACKETS = 'BRACKETS',
    CASHBACK = 'CASHBACK',
    PRICE_LIST = 'PRICE_LIST',
    OPERATOR_CHOICE = 'OPERATOR_CHOICE',
    LESS_PRICE = 'LESS_PRICE',
    HIGH_PRICE = 'HIGH_PRICE',
    ITEMS = 'ITEMS',
    POINTS = 'POINTS',
    GIFTS = 'GIFTS',
  }


}
