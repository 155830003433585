import { isNullish } from "../../../../utils/Functions";
import {
  ConditionPost, ConditionResponse, DiscountPost, PromotionPost, PromotionResponse, PromotionTagAdditionalInfoPost, RulePost, 
  RuleResponse, 
} from "../../../generated/api";
import { assert } from "../../../shared/failures/AssertError";
import { UnsupportedMappingError } from "../../../shared/failures/MappingsError";
import { PromoModel } from "../models/PromoModel";
import {
  PromoAmountModel,
  PromoApplyToModel,
  PromoGiftRuleModel,
  PromoMinAmountModel,
  PromoMinQuantityModel, 
  PromoRepeatArticlesModel,
} from "../models/PromoModelCommons";
import { PromoSaveModel } from "../models/PromoSaveModel";
import { PromoAmountModelType, PromoModelType, promoRuleModelTypes } from "../models/PromoTypes";
import { PromoRuleMappings } from "./PromoRuleMappings";

export class PromoMappings {
  static responseToModel = (promotion: PromotionResponse): PromoModel => {
    const discount = promotion.discounts.at(0);
    const discountType = discount?.dtype;

    const rule = promotion.rules.at(0);
    const ruleType = rule?.conditions.at(0)?.type;
    const minAmountRule = discount?.rules.find((e) => e.ruleType === RuleResponse.ruleType.MIN_AMOUNT);
    const minQuantityRule = discount?.rules.find((e) => e.ruleType === RuleResponse.ruleType.MIN_QUANTITY);

    if (
      ruleType === "BUSINESS_PARTNER" || 
      ruleType === "BUSINESS_PARTNER_GROUP" || 
      ruleType === "PRICE_LIST" 
    ) {
      throw new UnsupportedMappingError("rule.condition.type", ruleType, "applyTo.type");
    }
    const isUnsupportedApplyToType = (type: ConditionResponse.type): 
      type is [
        ConditionResponse.type.PRICE, 
        ConditionResponse.type.COUPON,
        ConditionResponse.type.CAMPAIGN,
        ConditionResponse.type.LOYALTY_LEVEL
      ][number] => [ "PRICE", "COUPON", "CAMPAIGN", "LOYALTY_LEVEL" ].includes(type);
    const repeatArticlesOrMinQuantity = isNullish(minQuantityRule) ? undefined : {
      value: minQuantityRule.value,
      rules: minQuantityRule.conditions.map((e) => {
        return PromoRuleMappings.responseToModel(e, [ "ITEM", "ITEM_GROUP" ]);
      }),
    };

    const repeatAmountsOrMinTotalExpense = isNullish(minAmountRule) ? undefined : {
      value: minAmountRule.value,
      rules: minAmountRule.conditions.map((e) => {
        return PromoRuleMappings.responseToModel(e, [ "ITEM", "ITEM_GROUP" ]);
      }),
    };

    return {
      id: promotion.id,
      active: promotion.active,
      code: promotion.code,
      name: promotion.description,
      type: PromoMappings.#typeResponseToModel(promotion.ptype),
      amount: {
        value: discount?.discount || 0,
        type: discountType || DiscountPost.dtype.AMOUNT,
      },
      repeatArticles: minQuantityRule?.repeatable ? repeatArticlesOrMinQuantity : undefined,
      repeatAmounts: minAmountRule?.repeatable ? repeatAmountsOrMinTotalExpense : undefined,
      applyTo: {
        type: !ruleType || isUnsupportedApplyToType(ruleType) ? promotion.ptype === "POINTS" ? "ITEM" : "ENTIRE_RECEIPT" : ruleType,
        rules: rule?.conditions.map(PromoRuleMappings.responseToBasicModel) ?? [],
      },
      minAmount: !minAmountRule?.repeatable ? repeatAmountsOrMinTotalExpense : undefined,
      minQuantity: !minQuantityRule?.repeatable ? repeatArticlesOrMinQuantity : undefined,
      isLoyaltyPartner: promotion.checkLoyaltyBP,
      proposed: promotion.proposal,
      rules: promotion.conditions.map((e) => {
        return PromoRuleMappings.responseToModel(e, promoRuleModelTypes);
      }),
      giftRules: promotion.ptype === "GIFTS" ? promotion.rules.map(PromoRuleMappings.responseToGiftRule) : [],
      tags: promotion.promotionTagValues || [],
      shortDescription: promotion.shortDescription || "",
      useTags: promotion.showData,
      tagsAdditionalInfo: promotion.promotionTagAdditionalInfos || [],
    };
  };

  static saveModelToResponse = (model: PromoSaveModel): PromotionPost => {
    let rules: RulePost[];
    let discount: DiscountPost | undefined;
    if (model.type === "TOTAL") {
      rules = [];
    }
    else if (model.type === "GIFTS") {
      rules = model.giftsRules.map(PromoMappings.#giftsSaveModelToResponse);
    }
    else {
      rules = [ PromoMappings.#applyToSaveModelToResponse(model.applyTo) ];
    }
    if (model.type !== "GIFTS") {
      discount = PromoMappings.#amountModelToResponse(
        model.amount, 
        model.minAmount, 
        model.minQuantity, 
        model.repeatArticles, 
        model.repeatAmounts
      );
    }
    const conditions = model.rules.map(PromoRuleMappings.modelToResponse);
    return {
      applicationType: PromotionPost.applicationType.DEFAULT,
      description: model.description,
      checkLoyaltyBP: model.isLoyaltyPartner,
      discounts: discount ? [ discount ] : [],
      proposal: model.proposed,
      rules,
      conditions,
      ptype: PromoMappings.#typeModelToResponse(model.type),
      showData: model.useTags,
      shortDescription: model.shortDescription,
      promotionTags: model.tags,
      promotionTagAdditionalInfos: model.tagsAdditionalInfo,
    };
  };

  static #typeResponseToModel = (type: undefined | PromotionResponse.ptype): PromoModelType => {
    switch (type) {
    case undefined:
      return "TOTAL";
    case PromotionResponse.ptype.TOTAL:
    case PromotionResponse.ptype.LESS_PRICE:
    case PromotionResponse.ptype.HIGH_PRICE:
    case PromotionResponse.ptype.ITEMS:
    case PromotionResponse.ptype.POINTS:
    case PromotionResponse.ptype.GIFTS:
      return type;
    case PromotionResponse.ptype.BRACKETS:
    case PromotionResponse.ptype.CASHBACK:
    case PromotionResponse.ptype.PRICE_LIST:
    case PromotionResponse.ptype.OPERATOR_CHOICE:
      throw new UnsupportedMappingError("type", type, "type");
    }
  };

  static #typeModelToResponse = (type: PromoModelType): PromotionPost.ptype => {
    switch (type) {
    case "LESS_PRICE":
      return PromotionPost.ptype.LESS_PRICE;
    case "HIGH_PRICE":
      return PromotionPost.ptype.HIGH_PRICE;
    case "TOTAL":
      return PromotionPost.ptype.TOTAL;
    case "ITEMS":
      return PromotionPost.ptype.ITEMS;
    case "POINTS":
      return PromotionPost.ptype.POINTS;
    case "GIFTS":
      return PromotionPost.ptype.GIFTS;
    }
  };

  static #amountModelToResponse = (
    amount: PromoAmountModel,
    minAmount: undefined | PromoMinAmountModel,
    minQuantity: undefined | PromoMinQuantityModel,
    repeatArticles: undefined | PromoRepeatArticlesModel,
    repeatAmounts: undefined | PromoRepeatArticlesModel
  ): DiscountPost => {
    assert(!(!isNullish(minQuantity) && !isNullish(repeatArticles)), "You can only pass 'minQuantity' or 'repeatArticles'");
    assert(!(!isNullish(minAmount) && !isNullish(repeatAmounts)), "You can only pass 'minAmount' or 'repeatAmounts'");

    const rules: Array<RulePost> = [];

    if (!isNullish(minAmount)) {
      rules.push(PromoMappings.#minAmountModelToResponse(minAmount));
    }
    else if (!isNullish(repeatAmounts)) {
      rules.push(PromoMappings.#repeatAmountsModelToResponse(repeatAmounts));
    }
    if (!isNullish(minQuantity)) {
      rules.push(PromoMappings.#minQuantityModelToResponse(minQuantity));
    } 
    else if (!isNullish(repeatArticles)) {
      rules.push(PromoMappings.#repeatArticlesModelToResponse(repeatArticles));
    }

    return {
      dtype: PromoMappings.#amountTypeModelToResponse(amount.type),
      discount: amount.value,
      rules,
    };
  };

  static #amountTypeModelToResponse = (condition: PromoAmountModelType): DiscountPost.dtype => {
    switch (condition) {
    case "AMOUNT":
      return DiscountPost.dtype.AMOUNT;
    case "PERCENTAGE":
      return DiscountPost.dtype.PERCENTAGE;
    case "FORCED_PRICE":
      return DiscountPost.dtype.FORCED_PRICE;
    }
  };

  static #applyToSaveModelToResponse = (model: PromoApplyToModel): RulePost => {
    const type = model.type;
    return {
      ruleType: RulePost.ruleType.APPLICABLE,
      repeatable: false,
      conditions: type === "ENTIRE_RECEIPT" ? [] : model.rules.map((data) => {
        return PromoRuleMappings.modelToResponse({
          ...data,
          type,
        });
      }),
    };
  };

  static #giftsSaveModelToResponse = (model: PromoGiftRuleModel): RulePost => {
    return {
      ruleType: RulePost.ruleType.APPLICABLE,
      repeatable: false,
      conditions: [ 
        {
          filter: ConditionPost.filter.EQUAL,
          type: ConditionPost.type.ITEM,
          fieldIDs: [ model.itemID || 0 ],
        }, 
        ...model.conditions.map(PromoRuleMappings.modelToResponse),
      ],
    };
  };

  static #minAmountModelToResponse = (model: PromoMinAmountModel): RulePost => {
    return {
      ruleType: RulePost.ruleType.MIN_AMOUNT,
      value: model.value,
      repeatable: false,
      conditions: model.rules.map(PromoRuleMappings.modelToResponse),
    };
  };

  static #minQuantityModelToResponse = (model: PromoMinQuantityModel): RulePost => {
    return {
      ruleType: RulePost.ruleType.MIN_QUANTITY,
      value: model.value,
      repeatable: false,
      conditions: model.rules.map(PromoRuleMappings.modelToResponse),
    };
  };

  static #repeatArticlesModelToResponse = (model: PromoRepeatArticlesModel): RulePost => {
    return {
      ruleType: RulePost.ruleType.MIN_QUANTITY,
      value: model.value,
      repeatable: true,
      conditions: model.rules.map(PromoRuleMappings.modelToResponse),
    };
  };

  static #repeatAmountsModelToResponse = (model: PromoRepeatArticlesModel): RulePost => {
    return {
      ruleType: RulePost.ruleType.MIN_AMOUNT,
      value: model.value,
      repeatable: true,
      conditions: model.rules.map(PromoRuleMappings.modelToResponse),
    };
  };
}