import { 
  Autocomplete, 
  Button, 
  Card, 
  CardContent, 
  CardHeader,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField, 
  Typography, 
} from "@mui/material";
import { PromoFormMachine } from "../../../../../../domain/features/promotions/state-machines/PromoFormMachine";
import { IFormValues } from "../../types";
import { CheckboxField } from "../../../../../shared/form/fields/basic/CheckboxField";
import { Add, Delete } from "@mui/icons-material";
import { useMachineStateSelector } from "../../../../../shared/hooks/useMachineStateSelector";
import { PromotionTagAdditionalInfoPost, PromotionTagPost } from "../../../../../../domain/generated/api";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { AutocompleteGroupItems } from "../filter-items-table/AutocompleteGroupItems";
import { groupActions } from "../../../../../shared/constants/Actions";
import { useAuth } from "../../../../../shared/hooks/authentication/useAuth";
import { PromoRuleModelType } from "../../../../../../domain/features/promotions/models/PromoTypes";
import { StyledTableCell, StyledTableContainer } from "../../../promotions-table/PromotionsTable.styles";
import { theme } from "../../../../../../muiTheme";
import { NumericInput } from "../../../../../shared/numeric-input/NumericInput";

interface TagsFormProps {
  machine: PromoFormMachine;
  existingTags: {
    tag: string;
    description?: string;
  }[];
}

interface TagsFormItemProps {
  existingTags: TagsFormProps["existingTags"];
  field: PromotionTagPost;
  remove(): void;
  update(value: IFormValues["tags"][number]): void;
  isAlone: boolean;
}

interface TagsAdditionalInfoFormItemProps {
  field: PromotionTagAdditionalInfoPost;
  remove(): void;
  update(value: PromotionTagAdditionalInfoPost): void;
  organizationId: number;
}

const TagsForm: React.FC<TagsFormProps> = ({ machine, existingTags }) => {
  const { t } = useTranslation("createPromo");
  const { user } = useAuth();
  const useTags = useMachineStateSelector(machine.select((state) => state.useTags.value));
  const tags = useMachineStateSelector(machine.select((state) => state.tags.value));
  const tagsAdditionalInfo = useMachineStateSelector(machine.select((state) => state.tagsAdditionalInfo.value));
  useEffect(() => {
    if (useTags) {
      if (!tags.length) {
        machine.changeTags([
          { 
            tag: "", 
            description: "", 
            tagValues: [], 
          },
        ]);
      }
    }
    else if (!useTags) {
      machine.changeTags([]);
      machine.changeTagsAdditionalInfo([]);
    }
  }, [ machine, tags.length, tagsAdditionalInfo.length, useTags ]);
  return <Card sx={{ mb: 4, backgroundColor: "transparent" }} variant="outlined">
    <CardHeader
      disableTypography
      title={<FormControlLabel
        sx={{ m: 0 }}
        control={<CheckboxField 
          value={useTags} 
          checked={useTags}
          readOnly={machine.state.useTags.isReadOnly} 
          onChange={(e) => machine.changeUseTags(e.target.checked)}
        />}
        disabled={machine.state.useTags.isReadOnly}
        label={<Typography fontWeight="bold">{t("use_tags").toUpperCase()}</Typography>}
      />}
    />
    {useTags && 
      <CardContent>
        <Stack spacing={2}>
          {tags.map((field, index) => <TagsFormItem
            isAlone={tags.length === 1 && index === 0}
            key={index}
            remove={() => {
              const newTags = tags.slice();
              newTags.splice(index, 1);
              machine.changeTags(newTags);
            }}
            update={(value) => {
              const newValue = tags.slice();
              newValue[index] = {
                ...newValue[index],
                ...value,
              };
              machine.changeTags(newValue);
            }}
            existingTags={existingTags}
            field={field}
          />)}
        </Stack>
        <Button 
          disabled={!tags.at(-1)?.tag}
          variant="outlined" 
          onClick={() => machine.changeTags([
            ...tags,
            { tag: "", description: "", tagValues: [] },
          ])}
          sx={{ mt: "1.5rem" }}
        >
          <Add />
          {t("add_tag")}
        </Button>
        <Divider sx={{ my: "2rem" }} />
        <StyledTableContainer style={{ borderRadius: theme.shape.borderRadius }}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  Discount type
                </StyledTableCell>
                <StyledTableCell>
                  Value
                </StyledTableCell>
                <StyledTableCell>
                  Operations
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tagsAdditionalInfo.map((field, index) => <TagsAdditionalInfoFormItem
                field={field}
                key={index}
                organizationId={user!.organizationId}
                remove={() => {
                  const copy = tagsAdditionalInfo.slice();
                  copy.splice(index, 1);
                  machine.changeTagsAdditionalInfo(copy);
                }}
                update={(value: PromotionTagAdditionalInfoPost) => {
                  const copy = tagsAdditionalInfo.slice();
                  copy[index] = {
                    ...copy[index],
                    ...value,
                  };
                  machine.changeTagsAdditionalInfo(copy);
                }}
              />)
              }
              <TableRow style={{ backgroundColor: theme.palette.grey[50] }}>
                <StyledTableCell />
                <StyledTableCell />
                <StyledTableCell>
                  <Button 
                    disableElevation 
                    color="inherit" 
                    variant="contained"
                    onClick={() => machine.changeTagsAdditionalInfo([
                      ...tagsAdditionalInfo,
                      {
                        discountType: PromotionTagAdditionalInfoPost.discountType.AMOUNT,
                        value: 0.0,
                        fieldIDsIncluded: [],
                        typeIncluded: PromotionTagAdditionalInfoPost.typeIncluded.ITEM,
                        typeExcluded: PromotionTagAdditionalInfoPost.typeExcluded.ITEM,
                        fieldIDsExcluded: [],
                      },
                    ])}
                  >
                    <Add />
                  </Button>
                </StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </StyledTableContainer>
      </CardContent>
    }
  </Card>;
};

TagsForm.displayName = "TagsForm";

const TagsFormItem: React.FC<TagsFormItemProps> = ({ remove, update, existingTags: tags, field, isAlone }) => {
  const { t } = useTranslation("createPromo");
  return <div style={{ display: "grid", gridColumn: 4, gridTemplateColumns: "0.5fr 0.7fr 1fr auto", gap: "0.5rem" }}>
    <Autocomplete
      freeSolo
      selectOnFocus
      clearOnBlur
      size="small"
      options={tags}
      value={field}
      inputValue={field.tag}
      renderInput={(props) => <TextField {...props} fullWidth label="Tag" />}
      getOptionLabel={(option) => `${option.tag}`}
      onInputChange={(event, value) => update({ ...field, tag: value })}
      onChange={(event, value) => {        
        if (value && typeof value !== "string") {
          update(value);
        }
      }}
    />
    <TextField 
      size="small"
      value={field.description} 
      onChange={(e) => update({ ...field, description: e.target.value })}
      label={t("tag_description")}
      fullWidth
    />
    <Autocomplete
      multiple
      freeSolo
      size="small"
      value={field.tagValues}
      options={field.tagValues || []}
      onChange={(event, value) => update({ ...field, tagValues: value as string[] })}
      renderInput={(props) => <TextField {...props} fullWidth size="small" label={t("tag_values")} />}
      limitTags={3}
    />
    <Button disabled={isAlone} variant="outlined" onClick={remove}>
      <Delete />
    </Button>
  </div>;
}; 

TagsFormItem.displayName = "TagsFormItem";

const TagsAdditionalInfoFormItem: React.FC<TagsAdditionalInfoFormItemProps> = ({ 
  field, 
  remove, 
  update, 
  organizationId,
}) => {
  const [ exclude, setExclude ] = useState(!!field.typeExcluded && !!field.fieldIDsExcluded?.length);
  const { t } = useTranslation("createPromo");
  useEffect(() => {
    if (!exclude && (field.typeExcluded || field.fieldIDsExcluded)) {
      const { typeExcluded, fieldIDsExcluded, ...rest } = field;
      update(rest);
    }
  }, [ exclude, field, update ]);
  return <>
    <TableRow style={{ backgroundColor: theme.palette.grey[50] }}>
      <StyledTableCell>
        <Select
          size="small"
          value={field.discountType}
          onChange={(event) => {
            if (typeof event.target.value === "string") {
              update({
                ...field,
                discountType: event.target.value as PromotionTagAdditionalInfoPost.discountType,
              });
            }
          }}
        >
          <MenuItem value="AMOUNT">
            {t("amount_types.AMOUNT")}
          </MenuItem>
          <MenuItem value="PERCENTAGE">
            {t("amount_types.PERCENTAGE")}
          </MenuItem>
          <MenuItem value="FORCED_PRICE">
            {t("amount_types.FORCED_PRICE")}
          </MenuItem>
        </Select>
      </StyledTableCell>
      <StyledTableCell>
        <NumericInput
          precision={2}
          decimalChar=","
          thousandChar="."
          variant="outlined"
          size="small"
          value={field.value}
          onChange={(e) => update({
            ...field,
            value: e.target.value || 0.0,
          })}
        />
      </StyledTableCell>
      <StyledTableCell>
        <IconButton onClick={remove}>
          <Delete />
        </IconButton>
      </StyledTableCell>
    </TableRow>
    <TableRow>
      <TableCell colSpan={3}>
        <Stack spacing={2}>
          <Stack direction="row" spacing={2} alignItems="center">
            <FormControlLabel
              control={<Checkbox readOnly checked disabled />}
              label={<Typography fontWeight="bold">Include</Typography>}
            />
            <FormControl sx={{ width: "30%" }}>
              <InputLabel>
                Type
              </InputLabel>
              <Select
                size="small"
                label="Type"
                value={field.typeIncluded}
                onChange={(event) => {
                  if (typeof event.target.value === "string") {
                    update({
                      ...field,
                      typeIncluded: event.target.value as PromotionTagAdditionalInfoPost.typeIncluded,
                    });
                  }
                }}
              >
                <MenuItem value="ITEM">
                  {t("apply_ITEM")}
                </MenuItem>
                <MenuItem value="ITEM_GROUP">
                  {t("apply_ITEM_GROUP")}
                </MenuItem>
              </Select>
            </FormControl>
            <AutocompleteGroupItems
              label="Search..."
              value={field.fieldIDsIncluded || []}
              fetchOptions={(ids) => {
                return groupActions.readAll(
                  organizationId, 
                  field.typeIncluded as PromoRuleModelType, 
                  { ids }
                );
              }}
              searchOptions={(userInput) => {
                return groupActions.search(
                  organizationId, 
                  { type: field.typeIncluded as PromoRuleModelType, userInput }
                );
              }}
              onChange={(items) => {
                update({
                  ...field,
                  fieldIDsIncluded: items,
                });
              }}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <Stack direction="row" alignItems="center">
              <FormControlLabel
                control={<Checkbox 
                  value={exclude} 
                  checked={exclude} 
                  onChange={(e) => setExclude(e.target.checked)} 
                />}
                label={<Typography fontWeight="bold">Exclude</Typography>}
              />
            </Stack>
            {exclude && <>
              <FormControl sx={{ width: "30%" }}>
                <InputLabel>
                Type
                </InputLabel>
                <Select
                  label="Type"
                  size="small"
                  value={field.typeExcluded}
                  onChange={(event) => {
                    if (typeof event.target.value === "string") {
                      update({
                        ...field,
                        typeExcluded: event.target.value as PromotionTagAdditionalInfoPost.typeExcluded,
                      });
                    }
                  }}
                >
                  <MenuItem value="ITEM">
                    {t("apply_ITEM")}
                  </MenuItem>
                  <MenuItem value="ITEM_GROUP">
                    {t("apply_ITEM_GROUP")}
                  </MenuItem>
                </Select>
              </FormControl>
              <AutocompleteGroupItems
                label="Search..."
                value={field.fieldIDsExcluded || []}
                fetchOptions={(ids) => {
                  return groupActions.readAll(
                    organizationId, 
                  field.typeExcluded as PromoRuleModelType, 
                  { ids }
                  );
                }}
                searchOptions={(userInput) => {
                  return groupActions.search(
                    organizationId, 
                    { type: field.typeExcluded as PromoRuleModelType, userInput }
                  );
                }}
                onChange={(items) => {
                  update({
                    ...field,
                    fieldIDsExcluded: items,
                  });
                }}
              />
            </>
            }
          </Stack>
        </Stack>
      </TableCell>
    </TableRow>
  </>;
};

TagsAdditionalInfoFormItem.displayName = "TagsAdditionalInfoFormItem";

export { TagsForm };