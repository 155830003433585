import { LoadingButton } from "@mui/lab";
import { Button, Chip, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { PromoModel } from "../../../../domain/features/promotions/models/PromoModel";
import { isNullish } from "../../../../utils/Functions";
import { promoActions } from "../../../shared/constants/Actions";
import { useAuth } from "../../../shared/hooks/authentication/useAuth";
import { Pages } from "../../../shared/pages";
import { StyledTableCell, StyledTableRow } from "./PromotionsTable.styles";

interface PromotionTableRowProps {
  readonly promo: PromoModel;
  refetchPromos: VoidFunction;
}

export const PromotionTableRow: React.FC<PromotionTableRowProps> = ({ promo, refetchPromos }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("promotionsTable");
  const { organization } = useAuth();
  const organizationId = organization?.id;

  const isActivePromo = promo.active;
  const isEditablePromo = isNullish(promo.code) && !promo.active;

  const {
    isLoading: isDeactivating,
    refetch: deactivatePromo,
    isSuccess: isDeactivated, 
  } = useQuery([ "deactivatePromotion", promo.id ], () => {
    return promoActions.deactivate(organizationId!, promo.id);
  }, { enabled: false });

  const onDeactivateClick = () => {
    return deactivatePromo();
  };

  useEffect(() => {
    if (isDeactivated) {
      return refetchPromos();
    }
  }, [ isDeactivated, refetchPromos ]);
  return (
    <StyledTableRow>
      <StyledTableCell>
        <Chip
          label={t(isActivePromo ? "activated" : "deactivated")}
          color={isActivePromo ? "success" : "default"}
          sx={{ minWidth: "90px" }}
        />
      </StyledTableCell>
      <StyledTableCell>
        <Typography>{promo.code ?? "N/A"}</Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Typography>{promo.name}</Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Stack
          spacing={1}
          direction="row"
          justifyContent="center"
          sx={{ "&.MuiButton-root": { minWidth: "121px" } }}
        >
          <Button
            onClick={() => navigate(Pages.promoEdit(promo.id))}
            variant="outlined"
          >
            {t(isEditablePromo ? "edit" : "details")}
          </Button>
          {isActivePromo
            ? <LoadingButton variant="outlined" loading={isDeactivating} onClick={onDeactivateClick}>
              {t("deactivate")}
            </LoadingButton>
            : null}
          {/*Todo: handle activate promo here */}
        </Stack>
       
      </StyledTableCell>
    </StyledTableRow>
  );
};

