import React from "react";
import { FormControl, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import { Controller, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { promoModelTypes } from "../../../../../../domain/features/promotions/models/PromoTypes";
import { useValueChanges } from "../../../../../shared/hooks/basic/UseValueChanges";
import { useMachineStateSelector } from "../../../../../shared/hooks/useMachineStateSelector";
import { IFormValues } from "../../types";

import { PromoAmountFields } from "../promo-amount-fields/PromoAmountFields";
import { usePromoStore } from "../../stores/PromoStore";

export const CommonForm:React.FC = () => {
  const { t } = useTranslation([ "createPromo" , "errors" ]);
  const { machine } = usePromoStore();

  const state = useMachineStateSelector(machine.select((state) => {
    return {
      isNameReadonly: state.name.isReadOnly,
      isTypeReadonly: state.type.isReadOnly,
      isShortDescriptionReadonly: state.shortDescription.isReadOnly,
    };
  }));

  const promoType = useWatch<IFormValues, "promoType">({ name: "promoType" });

  const promoName = useWatch<IFormValues, "promoName">({ name: "promoName" });

  const shortDescription = useWatch<IFormValues, "shortDescription">({ name: "shortDescription" });

  // update state machine every time fields valued changes
  useValueChanges(promoType, (value) => {
    machine.changeType(value);
  }, [ machine ]);

  useValueChanges(promoName, (value) => {
    machine.changeName(value);
  }, [ machine ]);

  useValueChanges(shortDescription, (value) => {
    machine.changeShortDescription(value);
  }, [ machine ]);

  return (
    <>
      <Stack direction="row" alignItems="center" sx={{ width: "100%" }}>
        <Controller<IFormValues, "promoName">
          name="promoName"
          rules={{ required: String(t("errors:name_is_required", { ns: "errors" })) }}
          render={({ field, fieldState: { error } }) =>
            <TextField
              size="small"
              sx={{ width: "70%" }}
              label={t("createPromo:promo_name")}
              error={!!error}
              helperText={error ? error.message : null}
              InputProps={{ readOnly: state.isNameReadonly }}
              {...field}
            />}
        />
        <Controller<IFormValues, "promoType">
          name="promoType"
          render={({ field }) =>
            <FormControl sx={{ m: 1, width: "30%" }}>
              <InputLabel id="create-promo-type">{t("createPromo:promo_type_label")}</InputLabel>
              <Select
                size="small"
                label={t("createPromo:promo_type_label")}
                labelId="create-promo-type"
                readOnly={state.isTypeReadonly}
                {...field}
              > 
                {promoModelTypes.map((item, i) => {
                  return <MenuItem key={i} value={item}>
                    {t(`createPromo:promo_types.${item}`)}
                  </MenuItem>;
                })}
              </Select>
            </FormControl>
          }
        />
      </Stack>
      <Stack direction="row" alignItems="center" sx={{ width: "100%" }}>
        <Controller<IFormValues, "shortDescription">
          name="shortDescription"
          render={({ field }) =>
            <TextField
              size="small"
              sx={{ width: [ "POINTS", "GIFTS" ].includes(promoType) ? "100%" : "50%" }}
              label={t("createPromo:tags_short_description")}
              InputProps={{ readOnly: state.isShortDescriptionReadonly }}
              {...field}
            />}
        />
        {![ "POINTS", "GIFTS" ].includes(promoType) && <PromoAmountFields />}
      </Stack>
    </>
  );
};
