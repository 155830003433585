/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ElementQueueErrorCollectionResponse } from '../models/ElementQueueErrorCollectionResponse';
import type { SynchronisationCollectionResponse } from '../models/SynchronisationCollectionResponse';
import type { SynchronisationErrorCollectionResponse } from '../models/SynchronisationErrorCollectionResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ManagerApiToGetSynchronisationService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get all synchronisation elements for organization
   * @returns SynchronisationCollectionResponse OK
   * @throws ApiError
   */
  public getAllSyncs({
    organizationId,
  }: {
    organizationId: number,
  }): CancelablePromise<SynchronisationCollectionResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/manager/organization/{organizationID}/synchronisations',
      path: {
        'organizationID': organizationId,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Get all queue errors for organization
   * @returns ElementQueueErrorCollectionResponse OK
   * @throws ApiError
   */
  public getAllElementQueueErrors({
    organizationId,
    element,
  }: {
    organizationId: number,
    element?: 'VAT_GROUP' | 'PAYMENT_TERM' | 'LOYALTY_CONFIGURATION' | 'BUSINESS_PARTNER_GROUP' | 'ITEM_GROUP' | 'WAREHOUSE' | 'PRICE_LIST' | 'BUSINESS_PARTNER' | 'ITEM' | 'ALTERNATE_CAT_NUM' | 'PRICE_LIST_ITEM' | 'EMPLOYEE' | 'LOYALTY_CUSTOMER' | 'INVENTORY_REPORT' | 'WAREHOUSE_LOCATION' | 'PASSIVE_DOCUMENT' | 'DOCUMENT' | 'ORDER' | 'INVOICE' | 'RECEIPT' | 'RETURN' | 'CREDIT_NOTE' | 'FISCAL_REPORT' | 'MESSAGE' | 'QUERY' | 'UDT' | 'COUNTERS' | 'GENERIC',
  }): CancelablePromise<ElementQueueErrorCollectionResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/manager/organization/{organizationID}/queue/errors',
      path: {
        'organizationID': organizationId,
      },
      query: {
        'element': element,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

  /**
   * Get all synchronisation errors for organization
   * @returns SynchronisationErrorCollectionResponse OK
   * @throws ApiError
   */
  public getAllSyncErrors({
    organizationId,
    bridgeId,
  }: {
    organizationId: number,
    bridgeId: number,
  }): CancelablePromise<SynchronisationErrorCollectionResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/manager/organization/{organizationID}/bridge/{bridgeID}/errors',
      path: {
        'organizationID': organizationId,
        'bridgeID': bridgeId,
      },
      errors: {
        400: `Bad Request`,
        401: `Failed to decode authentication token`,
        404: `Entity Not found`,
        405: `Method Not Allowed`,
        409: `Data integrity violation`,
        417: `Expectation Failed`,
        500: `General I/O error`,
      },
    });
  }

}