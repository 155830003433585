import { contains, isEmpty, isNullish } from "../../../../utils/Functions";
import { StateMachine } from "../../../shared/state-machine/StateMachine";
import { PromoModel } from "../models/PromoModel";
import {
  PromoAmountModelType,
  PromoApplyToModelType,
  PromoModelType,
  PromoRuleModelItemType,
  PromoRuleModelType,
} from "../models/PromoTypes";
import { PromoRule } from "./RuleFormState";
import { PromoFormState } from "./PromoFormState";
import { PromoGiftRuleModel } from "../models/PromoModelCommons";
import { PromotionTagAdditionalInfoPost, PromotionTagPost } from "../../../generated/api";

export class PromoFormMachine extends StateMachine<PromoFormState> {
  constructor({ initialPromo }: { initialPromo?: undefined | PromoModel } = {}) {
    super(initialPromo === undefined
      ? PromoFormMachine.#resolveTypedState("ITEMS")
      : PromoFormMachine.#resolvePromoState(initialPromo));
  }

  // Uncomment this to log each state update for debugging

  // override updateState(state: PromoFormState): void {
  //   if (process.env.NODE_ENV === "development") {
  //     console.log(state);
  //   }
  //   return super.updateState(state);
  // }

  applyPromo(promo: PromoModel) {
    this.updateState(PromoFormMachine.#resolvePromoState(promo));
  }

  changeCode(value: string): void {
    this.updateState({
      ...this.state,
      code: {
        ...this.state.code,
        value: value,
      },
    });
  }

  changeName(value: string): void {
    this.updateState({
      ...this.state,
      name: { ...this.state.name, value },
    });
  }

  changeType(value: PromoModelType): void {
    this.updateState(PromoFormMachine.#resolveTypedState(value));
  }

  changeAmountValue(value: number): void {
    this.updateState({
      ...this.state,
      amount: {
        ...this.state.amount,
        value,
      },
    });
  }

  changeAmountType(value: PromoAmountModelType): void {
    this.updateState({
      ...this.state,
      amount: {
        ...this.state.amount,
        type: value,
      },
    });
  }

  changeApplyToType(type: PromoApplyToModelType): void {
    // TODO: You can't change a applyTo type when isn't visible

    this.updateState({
      ...this.state,
      applyTo: {
        ...this.state.applyTo,
        type,
        canSelect: type !== "ENTIRE_RECEIPT",
        rules: type === "ENTIRE_RECEIPT" ? [] : this.state.applyTo.rules,
      },
    });
  }

  changeApplyToRules(rules: Array<PromoRule<PromoRuleModelItemType>>): void {
    // TODO: You can't change a rules when isn't visible or can't select

    this.updateState({
      ...this.state,
      applyTo: {
        ...this.state.applyTo,
        rules,
      },
    });
  }

  changeRepeatArticlesCheck(isChecked: boolean): void {
    // TODO: You can't change a group when group is disabled

    this.updateState({
      ...this.state,
      repeatArticles: {
        ...this.state.repeatArticles,
        isChecked: isChecked,
        data: {
          ...this.state.repeatArticles.data,
          isDisabled: !isChecked,
        },
      },
      minTotalArticles: {
        ...this.state.minTotalArticles,
        isDisabled: isChecked,
      },
    });
  }

  changeRepeatAmountsCheck(isChecked: boolean): void {
    this.updateState({
      ...this.state,
      repeatAmounts: {
        ...this.state.repeatAmounts,
        isChecked: isChecked,
        data: {
          ...this.state.repeatAmounts.data,
          isDisabled: !isChecked,
        },
      },
      minTotalExpense: {
        ...this.state.minTotalExpense,
        isDisabled: isChecked,
      },
    });
  }

  changeRepeatArticles(value: number): void {
    // TODO: You can't change a data group when data group is disabled

    this.updateState({
      ...this.state,
      repeatArticles: {
        ...this.state.repeatArticles,
        data: {
          ...this.state.repeatArticles.data,
          value,
        },
      },
    });
  }

  changeRepeatAmounts(value: number): void {
    this.updateState({
      ...this.state,
      repeatAmounts: {
        ...this.state.repeatAmounts,
        data: {
          ...this.state.repeatAmounts.data,
          value,
        },
      },
    });
  }

  changeRepeatArticlesRules(rules: Array<PromoRule<PromoRuleModelItemType>>): void {
    // TODO: You can't change a data group when data group is disabled

    this.updateState({
      ...this.state,
      repeatArticles: {
        ...this.state.repeatArticles,
        data: {
          ...this.state.repeatArticles.data,
          rules,
        },
      },
    });
  }

  changeRepeatAmountsRules(rules: Array<PromoRule<PromoRuleModelItemType>>): void {
    this.updateState({
      ...this.state,
      repeatAmounts: {
        ...this.state.repeatAmounts,
        data: {
          ...this.state.repeatAmounts.data,
          rules,
        },
      },
    });
  }

  changeMinTotalExpenseCheck(isChecked: boolean): void {
    this.updateState({
      ...this.state,
      repeatAmounts: {
        ...this.state.repeatAmounts,
        isDisabled: isChecked,
      },
      minTotalExpense: {
        ...this.state.minTotalExpense,
        isChecked: isChecked,
        data: {
          ...this.state.minTotalExpense.data,
          isDisabled: !isChecked,
        },
      },
    });
  }

  changeMinTotalExpense(value: number): void {
    this.updateState({
      ...this.state,
      minTotalExpense: {
        ...this.state.minTotalExpense,
        data: {
          ...this.state.minTotalExpense.data,
          value,
        },
      },
    });
  }

  changeMinTotalExpenseRules(rules: Array<PromoRule<PromoRuleModelItemType>>): void {
    // TODO: You can't change a data group when data group is disabled

    this.updateState({
      ...this.state,
      minTotalExpense: {
        ...this.state.minTotalExpense,
        data: {
          ...this.state.minTotalExpense.data,
          rules,
        },
      },
    });
  }

  changeMinTotalArticlesCheck(isChecked: boolean): void {
    // TODO: You can't change a group when group is disabled

    this.updateState({
      ...this.state,
      repeatArticles: {
        ...this.state.repeatArticles,
        isDisabled: isChecked,
      },
      minTotalArticles: {
        ...this.state.minTotalArticles,
        isChecked: isChecked,
        data: {
          ...this.state.minTotalArticles.data,
          isDisabled: !isChecked,
        },
      },
    });
  }

  changeMinTotalArticles(value: number): void {
    // TODO: You can't change a data group when data group is disabled

    this.updateState({
      ...this.state,
      minTotalArticles: {
        ...this.state.minTotalArticles,
        data: {
          ...this.state.minTotalArticles.data,
          value,
        },
      },
    });
  }

  changeMinTotalArticlesRules(rules: Array<PromoRule<PromoRuleModelItemType>>): void {
    // TODO: You can't change a data group when data group is disabled

    this.updateState({
      ...this.state,
      minTotalArticles: {
        ...this.state.minTotalArticles,
        data: {
          ...this.state.minTotalArticles.data,
          rules,
        },
      },
    });
  }

  changeLoyaltyPartner(value: boolean): void {
    this.updateState({
      ...this.state,
      loyaltyPartner: {
        ...this.state.loyaltyPartner,
        value,
      },
    });
  }

  changeProposed(value: boolean): void {
    this.updateState({
      ...this.state,
      proposed: {
        ...this.state.proposed,
        value,
      },
    });
  }
  
  changeRules(value: PromoRule<PromoRuleModelType>[]): void {
    this.updateState({
      ...this.state,
      rules: {
        ...this.state.rules,
        value,
      },
    });
  }

  changeGiftRules(value: PromoGiftRuleModel[]) : void {
    this.updateState({
      ...this.state,
      giftsRules: {
        ...this.state.giftsRules,
        value,
      },
    });
  }

  changeShortDescription(value: string) {
    this.updateState({ 
      ...this.state,
      shortDescription: {
        ...this.state.shortDescription,
        value,
      },
    });
  }

  changeUseTags(value: boolean) {
    this.updateState({
      ...this.state,
      useTags: {
        ...this.state.useTags,
        value,
      },
    });
  }

  changeTags(value: PromotionTagPost[]) {
    this.updateState({
      ...this.state,
      tags: {
        ...this.state.tags,
        value,
      },
    });
  }

  changeTagsAdditionalInfo(value: PromotionTagAdditionalInfoPost[]) {
    this.updateState({
      ...this.state,
      tagsAdditionalInfo: {
        ...this.state.tagsAdditionalInfo,
        value,
      },
    });
  }

  static #resolvePromoState(promo: PromoModel): PromoFormState {
    const { code, type, applyTo, repeatArticles, minQuantity, repeatAmounts, minAmount } = promo;
    const isReadOnly = !isEmpty(code ?? "");
    return {
      code: { isReadOnly: true, value: code ?? "" },
      name: { isReadOnly, value: promo.name },
      type: { isReadOnly, value: type },
      amount: {
        isReadOnly,
        value: promo.amount.value,
        type: promo.amount.type,
      },
      applyTo: {
        isVisible: contains([ "LESS_PRICE", "HIGH_PRICE", "ITEMS", "POINTS" ], type),
        isReadOnly,
        type: applyTo.type,
        rules: applyTo.rules,
        canSelect: applyTo.type !== "ENTIRE_RECEIPT",
      },
      repeatArticles: {
        isVisible: contains([ "LESS_PRICE", "HIGH_PRICE", "ITEMS", "POINTS" ], type),
        isReadOnly,
        isDisabled: !isNullish(minQuantity),
        isChecked: !!repeatArticles,
        data: {
          isDisabled: !repeatArticles,
          value: repeatArticles?.value ?? 0,
          rules: repeatArticles?.rules ?? [],
        },
      },
      repeatAmounts: {
        isVisible: type === "POINTS",
        isReadOnly,
        isDisabled: !isNullish(minAmount),
        isChecked: !!repeatAmounts,
        data: {
          isDisabled: !repeatAmounts,
          value: repeatAmounts?.value ?? 0,
          rules: repeatAmounts?.rules ?? [],
        },
      },
      minTotalExpense: {
        isReadOnly,
        isDisabled: !isNullish(repeatAmounts),
        isChecked: !!minAmount,
        data: {
          isDisabled: minAmount === undefined,
          value: minAmount?.value ?? 0.0,
          rules: minAmount?.rules ?? [],
        },
      },
      minTotalArticles: {
        isDisabled: !isNullish(repeatArticles),
        isReadOnly,
        isChecked: !!minQuantity,
        data: {
          isDisabled: minQuantity === undefined,
          value: minQuantity?.value ?? 0,
          rules: minQuantity?.rules ?? [],
        },
      },
      loyaltyPartner: { isReadOnly, value: promo.isLoyaltyPartner },
      proposed: { isReadOnly, value: promo.proposed },
      rules: { isReadOnly, value: promo.rules },
      giftsRules: { isReadOnly, value: promo.giftRules },
      tags: { isReadOnly, value: promo.tags },
      shortDescription: { isReadOnly, value: promo.shortDescription },
      useTags: { isReadOnly, value: promo.useTags },
      tagsAdditionalInfo: {
        isReadOnly,
        value: promo.tagsAdditionalInfo as PromotionTagAdditionalInfoPost[],
      },
    };
  }

  static #resolveTypedState(type: PromoModelType): PromoFormState {
    return {
      code: { isReadOnly: true, value: "" },
      name: { isReadOnly: false, value: "" },
      type: { isReadOnly: false, value: type },
      amount: {
        isReadOnly: false,
        value: 0.0,
        type: "AMOUNT",
      },
      applyTo: {
        isVisible: contains([ "LESS_PRICE", "HIGH_PRICE", "ITEMS", "POINTS" ], type),
        isReadOnly: false,
        type: "ITEM",
        rules: [],
        canSelect: true,
      },
      repeatArticles: {
        isVisible: contains([ "LESS_PRICE", "HIGH_PRICE", "ITEMS", "POINTS" ], type),
        isReadOnly: false,
        isDisabled: false,
        isChecked: false,
        data: {
          isDisabled: true,
          value: 0,
          rules: [],
        },
      },
      repeatAmounts: {
        isVisible: type === "POINTS",
        isReadOnly: false,
        isDisabled: false,
        isChecked: false,
        data: {
          isDisabled: true,
          value: 0,
          rules: [],
        },
      },
      minTotalExpense: {
        isDisabled: false,
        isReadOnly: false,
        isChecked: false,
        data: {
          isDisabled: true,
          value: 0.0,
          rules: [],
        },
      },
      minTotalArticles: {
        isDisabled: false,
        isReadOnly: false,
        isChecked: false,
        data: {
          isDisabled: true,
          value: 0,
          rules: [],
        },
      },
      loyaltyPartner: { isReadOnly: false, value: false },
      proposed: { isReadOnly: false, value: false },
      rules: { isReadOnly: false, value: [] },
      giftsRules: { isReadOnly: false, value: [] },
      tags: { isReadOnly: false, value: [] },
      shortDescription: { isReadOnly: false, value: "" },
      useTags: { isReadOnly: false, value: false },
      tagsAdditionalInfo: {
        isReadOnly: false, 
        value: [],
      },
    };
  }
}
