import { BusinessPartnersRepository } from "../../business-partners/repositories/BusinessPartnersRepository";
import { ItemsRepository } from "../../items/repositories/ItemsRepository";
import { GroupRepository } from "../repositories/GroupRepository";
import { GroupModel } from "../models/GroupModel";
import { GroupMappings } from "../mappings/GroupMappings";
import { PromoRuleModelType } from "../../promotions/models/PromoTypes";

/**
 * Set of actions to populate the {@link PromoRuleModel}
 */
export class GroupActions {
  constructor(
    private readonly groupRepository: GroupRepository,
    private readonly itemsRepository: ItemsRepository,
    private readonly businessPartnersRepository: BusinessPartnersRepository,
  ) {}
  async readOne(organizationId: number, type: PromoRuleModelType, params: {id: number}): Promise<GroupModel> {
    switch (type) {
    case "ITEM": {
      const response = await this.itemsRepository.readOne(organizationId, params.id);
      return GroupMappings.groupItemResponseToGroupModel(response);
    }
    case "BUSINESS_PARTNER": {
      // TODO Implement
      return {} as Promise<GroupModel>;
    }
    case "ITEM_GROUP":
    case "COUPON":
    case "CAMPAIGN":
    case "PRICE":
      return {} as Promise<GroupModel>;
    case "LOYALTY_LEVEL":
    case "BUSINESS_PARTNER_GROUP": {
      const response = await this.groupRepository.readOne(organizationId, type, params);
      return GroupMappings.groupResponseToGroupModel(response);
    }
    }
  }
  async readAll(organizationId: number, type: PromoRuleModelType, params : {ids?: Array<number>} = {}): Promise<GroupModel[]> {
    switch (type) {
    case "ITEM": {
      const response = await this.itemsRepository.readAll(organizationId, params);

      return response.items?.map(GroupMappings.groupItemResponseToGroupModel) ?? [];
    }
    case "BUSINESS_PARTNER": {
      const response = await this.businessPartnersRepository.readAll(organizationId, params);

      return response.businessPartners?.map(GroupMappings.businessPartnerResponseToGroupModel) ?? [];
    }
    case "PRICE":
      return Promise.resolve([]);
    case "LOYALTY_LEVEL":
    case "ITEM_GROUP":
    case "COUPON":
    case "CAMPAIGN":
    case "BUSINESS_PARTNER_GROUP": {
      const response = await this.groupRepository.readAll(organizationId, type, params);
      return GroupMappings.responseToModel(response, type) ?? [];
    }
    }
  }
  async search(organizationId: number, params: { type: PromoRuleModelType; userInput: string }): Promise<Array<GroupModel>> {
    const apiResponse = await this.groupRepository.search(organizationId, params);
    return GroupMappings.responseToModel(apiResponse, params.type) ?? [];
  }
  
  async addOne(organizationId: number, type: PromoRuleModelType, params: {code: string; description: string}) {
    if (type === "ITEM" || type === "BUSINESS_PARTNER" || type === "PRICE") {
      throw new Error("ITEM type is not supported");
    }
    const response = await this.groupRepository.addOne(organizationId, {
      type,
      code: params.code,
      description: params.description,
    });
    return GroupMappings.groupResponseToGroupModel(response);
  }

  async changeDescription(organizationId: number, type: PromoRuleModelType, params: {typeId: number; description: string}) {
    if (type === "ITEM" || type === "BUSINESS_PARTNER" || type === "PRICE") {
      throw new Error("ITEM type is not supported");
    }
    const response = await this.groupRepository.changeDescription(organizationId, {
      type,
      typeId: params.typeId,
      description: params.description,
    });
    return GroupMappings.groupResponseToGroupModel(response);
  }
}
